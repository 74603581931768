import { useRef, useState } from "react";
import { validateEmail } from "../utils/HelperFunctions";
import { FormData } from "../utils/interfaces";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
import { Select, Textarea, TextInput } from "@mantine/core";
import { technologiesOptions } from "../utils/constants";

const initialFormState = {
  username: "",
  email: "",
  phone: "",
  message: "",
  technology: null,
};


const ContactForm = () => {
  const [formData, setFormData] = useState<FormData>(initialFormState);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const form = useRef<HTMLFormElement>(null);

  const handleInput = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Clear error when user starts typing
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSelectChange = (value: string | null) => {
    if (!value) {
      setFormErrors({ ...formErrors, technology: "Please select technology" });
      setFormData({ ...formData, technology: "" })
    } else {
      setFormErrors({ ...formErrors, technology: "" });
      setFormData({ ...formData, technology: value as string });
    }
  }

  console.log(formData,'@@@formData')

  const validateForm = () => {
    const errors: Record<string, string> = {};

    if (!formData?.username?.trim()) errors.username = "Name is required";
    if (!formData?.email?.trim() || !validateEmail(formData?.email))
      errors.email = "Valid email is required";
    if (!formData?.phone) errors.phone = "Phone number is required";
    if (!formData?.message?.trim()) errors.message = "Message cannot be empty";
    if (!formData?.technology) errors.technology = "Please select technology"
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill all required fields correctly.");
      return;
    }

    try {
      sendEmail(e);
      setFormData(initialFormState);
      setFormErrors({});
      toast.success("Message sent successfully!");
    } catch (err) {
      toast.error("There is some error, please try again later.");
    }
  };

  const sendEmail = (e: any) => {
    e.preventDefault();
    const SERVICE_KEY = "service_99hiczf";
    const PUBLIC_KEY = "Q9b_snzRQTN318hjZ";
    const TEMPLATE_ID = "template_kzm17wr";

    emailjs.sendForm(SERVICE_KEY, TEMPLATE_ID, form.current!, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleFormSubmit}
      method="post"
      ref={form}
    >
      <Toaster />
      <div className="gap-5 py-7 flex flex-col w-full">
        <div className="grid grid-cols-2 gap-5">
          <TextInput
            label="Name"
            type="text"
            name="username"
            placeholder="Name"
            onChange={handleInput}
            value={formData.username}
            error={formErrors.username}
          />
          <TextInput
            label="Email Address"
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={handleInput}
            value={formData.email}
            error={formErrors.email}
          />
        </div>
        <div className="grid grid-cols-2 gap-5">
          <TextInput
            label="Phone Number"
            type="number"
            name="phone"
            placeholder="Phone Number"
            onChange={handleInput}
            value={formData.phone}
            error={formErrors.phone}
          />
          <Select
            label="Technology"
            placeholder="Technology"
            value={formData.technology}
            onChange={(e) => handleSelectChange(e)}
            error={formErrors.technology}
            data={technologiesOptions}
          />
        </div>
      </div>
      <Textarea
        label="Your Message"
        placeholder="Message..."
        autosize
        name="message"
        onChange={handleInput}
        value={formData.message}
        minRows={5}
        error={formErrors.message}
      />
      <p className="text-xs font-poppins-regular text-bodyText pt-3.5">
        By proceeding, you are agreeing to Nexasoft Privacy Policy &
        communication
      </p>

      <button
        type="submit"
        className="w-full blueToYellow text-white text-base py-2.5 font-semibold rounded-lg mt-p30"
      >
        Send Enquiry
      </button>
    </form>
  );
};

export default ContactForm;
